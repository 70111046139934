'use client';
import HTMLComment from 'react-html-comment';
import { useQueryParam } from '../../customHooks/useQueryParam';

const RenderHTMLComment = ({ widgetType, id }: { widgetType: string; id?: string }) => {
  const search = useQueryParam('debug');

  return search !== null ? <HTMLComment text={widgetType + (id ? ` / id: ${id}` : '')} /> : <></>;
};

export default RenderHTMLComment;
