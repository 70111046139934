import { PageType } from '../../../context/AppContext';
import { AdSlotName, AdSlotType } from '../../../types/ads.d';
import { isMobile } from '../../../utils/detect';

export interface GujAd {
  getClient: () => {
    setSiteType: (type: AdSlotType) => void;
    setZone: (zone: string) => void;
    setKeywords: (keywords: Array<string>) => void;
    setIsIndexPage: (isIndex: boolean) => void;
    reloadAds: () => void;
  };
  cmd: Array<() => void>;
  pushArray: (adslotIds: Array<string>) => void;
  finalize: () => void;
  push: (adSlotId: string) => void;
}

declare global {
  interface Window {
    GujAd?: GujAd;
  }
}

interface InitGujAdParams {
  adZone: string;
  keywords?: Array<string>;
  pageType: PageType;
}

const getSiteType = () => (!isMobile() ? AdSlotType.Desktop : AdSlotType.Mobile);

/**
 *
 */
const initialize = ({ adZone, keywords, pageType }: InitGujAdParams) => {
  window.GujAd = window.GujAd ?? ({ cmd: [] } as unknown as GujAd);

  window.GujAd.cmd.push(() => {
    const gujAdClient = (window.GujAd as GujAd).getClient();
    gujAdClient.setSiteType(getSiteType());
    gujAdClient.setZone(adZone);
    if (keywords) {
      gujAdClient.setKeywords(keywords);
    }
    gujAdClient.setIsIndexPage(pageType === PageType.Overview);
  });
};

export const pushAdSlot = (adSlotId: AdSlotName) => {
  if (adSlotId && window?.GujAd?.cmd !== undefined) {
    window.GujAd.cmd.push(() => window.GujAd?.push(adSlotId.toLowerCase()));
  }
};

export const AdAllianceLib = {
  initialize,
  pushAdSlot,
};
