export const getGalleryRawData = async (galleryUrl: string) =>
  await fetch(`/nextapi/getgallery?url=${galleryUrl}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .catch((error) => {
      console.error(error);
    });
