import { useContext, useEffect, useRef, useState } from 'react';
import FoundationPlayer from 'components/UI/FoundationPlayer/FoundationPlayer';
import Icon from 'components/UI/Icon/Icon';
import VideoPlayer from 'components/UI/VideoPlayer/VideoPlayer';
import type { VideoProps } from 'components/UI/VideoPlayer/VideoPlayerDefault';
import VideoSwiperNew from 'components/UI/VideoSwiper/VideoSwiperNew';
import { AppContext } from 'context/AppContext';
import { isFeatureFlagEnabled } from 'utils/featureFlags';
import { videoEventsNew } from 'utils/video';
import type { WidgetTeaserVideoPlaylistProps } from './widget-teaser-playlist';
import styles from './widget-teaser-playlist-new.module.scss';

const WidgetTeaserPlaylistNew = ({ context }: { context: WidgetTeaserVideoPlaylistProps }) => {
  const { headline, link } = context.model.fields;
  const [isClient, setIsClient] = useState<boolean>(false);
  const videoList = context?.items?.map((item) => item.model) as Array<VideoProps>;
  const startType = useRef(0);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(-1);
  const [isAdPlaying, setIsAdPlaying] = useState<boolean>(false);
  const [totalClips, setTotalClips] = useState<number>(0);
  const [totalDuration, setTotalDuration] = useState<number>(0);
  const [currentAdTime, setCurrentAdTime] = useState<number>(0);
  const [contentFinished, setContentFinished] = useState<boolean>(false);
  const [videoPlaying, setVideoPlaying] = useState<boolean>(false);
  const [currentVideo, setCurrentVideo] = useState<VideoProps | undefined>(undefined);

  const { featureFlags } = useContext(AppContext);
  const isFoundationPayer = isFeatureFlagEnabled('ft_temp_enable_foundation-player', featureFlags);

  useEffect(() => setIsClient(true), []);

  useEffect(() => {
    if (contentFinished) {
      setCurrentVideoIndex(currentVideoIndex + 1);
    }

    return setContentFinished(false);
  }, [contentFinished, currentVideoIndex]);

  useEffect(() => {
    setCurrentVideo(currentVideoIndex === -1 ? undefined : videoList[currentVideoIndex]);
  }, [currentVideoIndex, videoList]);

  return (
    videoList && (
      <div className={styles.root}>
        {currentVideo && isClient && (
          <div className={styles.video}>
            {isFoundationPayer ? (
              <FoundationPlayer
                video={currentVideo}
                postRollDisabled
                isAdPlayingListener={setIsAdPlaying}
                totalClipsListener={setTotalClips}
                totalDurationListener={setTotalDuration}
                currentAdTimeListener={setCurrentAdTime}
                contentFinishedListener={setContentFinished}
                videoPlayingListener={setVideoPlaying}
                autoplay
              />
            ) : (
              <VideoPlayer
                video={currentVideo}
                noPostroll
                onEvents={videoEventsNew({
                  setIsAdPlaying,
                  setTotalClips,
                  setTotalDuration,
                  setCurrentAdTime,
                  setContentFinished,
                  setVideoPlaying,
                })}
                startType={startType.current}
                autoplay
              />
            )}
          </div>
        )}

        <VideoSwiperNew
          onClick={(index: number) => {
            startType.current = index;
            setCurrentVideoIndex(index);
          }}
          videoList={videoList}
          isAdPlaying={isAdPlaying}
          totalAdClips={totalClips}
          totalAdDuration={totalDuration}
          currentAdTime={currentAdTime}
          title={headline}
          titleLink={link}
          currentVideoIndex={currentVideoIndex}
          videoPlaying={videoPlaying}
        />

        <div className={styles.meta}>
          <Icon name="playlist" />
          Playlist <span>•</span>
          {videoList.length} Videos
        </div>
      </div>
    )
  );
};

export default WidgetTeaserPlaylistNew;
