import { type ReactNode } from 'react';
import { clsx } from 'clsx';
import type { PropsWithClassName } from '../../../types/react-props';
import { SponsoredIconFull } from '../SponsoredIcon';
import { Title, type TitleProps } from '../Title';
import styles from './TeaserGridSection.module.scss';

export type TeaserGridSectionProps = PropsWithClassName<{
  readonly children: ReactNode;
  readonly title?: TitleProps['children'];
  readonly titleLink?: TitleProps['link'];
  readonly titleAs?: TitleProps['as'];
  readonly gridColumns?: 2 | 3 | 5;
  readonly isRtlPlus?: boolean;
  readonly isSponsored?: boolean;
  readonly anchor?: string;
}>;

const TeaserGridSection = ({
  children,
  title,
  titleLink,
  titleAs = 'h2',
  gridColumns = 3,
  className,
  isRtlPlus = false,
  isSponsored = false,
  anchor,
}: TeaserGridSectionProps) => (
  <section className={clsx(styles.section, className)} id={anchor}>
    {!!title && (
      <Title link={titleLink} as={titleAs} isRtlPlus={isRtlPlus}>
        {title}
      </Title>
    )}
    {isSponsored && <SponsoredIconFull />}
    <div
      className={clsx(styles.grid, {
        [styles.twoColumns]: gridColumns === 2,
        [styles.threeColumns]: gridColumns === 3,
        [styles.fiveColumns]: gridColumns === 5,
      })}
    >
      {children}
    </div>
  </section>
);

export default TeaserGridSection;
