'use client';
import { useState } from 'react';
import { clsx } from 'clsx';
import type { MenuItemProps } from 'components/partials/widget/widget-menu/widget-menu-default';
import { Icon } from '../Icon';
import { Logo } from '../Logo';
import { MenuLink } from '../Menu';
import styles from './DropdownMenu.module.scss';

interface DropdownMenu {
  readonly menu: MenuItemProps;
  readonly className: string;
  readonly level: number;
  readonly backgroundColor: string;
}

export const DropdownMenu = ({ menu, className, level, backgroundColor }: DropdownMenu) => {
  const [isOpen, setIsOpen] = useState(false);

  const getBackgroundColor = () => {
    if (level === 1) {
      if (isOpen) {
        return '#000000';
      }
      return '#212427';
    }
    if (level === 3) {
      return `${backgroundColor}b2`;
    }

    return backgroundColor;
  };

  return (
    <>
      <li
        key={menu.title}
        className={clsx(className, styles.menu, isOpen ? styles.active : '')}
        style={{ backgroundColor: getBackgroundColor() }}
      >
        {level === 1 ? (
          <MenuLink menuItem={menu} className={styles.title}>
            <Logo
              className={styles.logo}
              colors={menu?.themenwelt?.logo_colors ?? ['#ffffff', '#ffffff', '#ffffff']}
              size="tiny"
            />
            <span>{menu.title}</span>
          </MenuLink>
        ) : (
          <MenuLink menuItem={menu} className={styles.title} />
        )}
        {menu.children && level < 3 && (
          <button className={clsx(styles.chevron, isOpen ? styles.flip : '')} onClick={() => setIsOpen(!isOpen)}>
            <Icon name="chevronDown" />
          </button>
        )}
      </li>
      {menu.children && level < 3 && (
        <ul
          className={clsx(
            styles.list,
            styles.lowerLevel,
            isOpen ? styles.open : styles.closed,
            level === 1 ? styles.spacer : ''
          )}
        >
          {menu.children.map((secondLevel: MenuItemProps) => {
            return (
              <DropdownMenu
                key={`${level + 1}-${secondLevel.title}`}
                menu={secondLevel}
                className={styles.lowerLevel}
                level={level + 1}
                backgroundColor={menu?.themenwelt?.primary_color ?? '#212427'}
              />
            );
          })}
        </ul>
      )}
    </>
  );
};
