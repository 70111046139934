'use client';

import { useRef, useState } from 'react';
import { CreditBox } from '../../../../UI/Creditbox/Creditbox';
import VideoPlayer from '../../../../UI/VideoPlayer/VideoPlayer';
import type { VideoProps } from '../../../../UI/VideoPlayer/VideoPlayerDefault';
import { VideoSwiper } from '../../../../UI/VideoSwiper';
import { videoplayerOnEvents } from './videoEvents';
import styles from './widget-video-with-related.module.scss';

export const WidgetVideoWithRelated = ({ context }: { context: VideoProps }) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(-1);
  const [showRelatedVideos, setShowRelatedVideos] = useState(false);
  const [isAdPlaying, setIsAdPlaying] = useState<boolean>(false);
  const startType = useRef(0);
  const { related = [], ...video } = context;
  const [totalDuration, setTotalDuration] = useState<number>(0);
  const [currentAdTime, setCurrentAdTime] = useState<number>(0);
  const [totalClips, setTotalClips] = useState<number>(0);

  const currentVideo = currentVideoIndex === -1 ? video : related[currentVideoIndex];

  return (
    <div className={styles.main} data-testid="video-player">
      <VideoPlayer
        video={currentVideo}
        noPostroll
        onEvents={videoplayerOnEvents({
          setShowRelatedVideos,
          setIsAdPlaying,
          startType,
          setTotalClips,
          setTotalDuration,
          setCurrentAdTime,
        })}
        startType={startType.current}
      />
      <CreditBox video={currentVideo} />
      {related.length > 0 && (
        <VideoSwiper
          videoList={related}
          initHidden={!showRelatedVideos}
          currentVideoIndex={currentVideoIndex}
          title={`${related.length} weitere Videos`}
          onClick={(index: number) => {
            startType.current = 2;
            setCurrentVideoIndex(index);
          }}
          isAdPlaying={isAdPlaying}
          totalAdClips={totalClips}
          totalAdDuration={totalDuration}
          currentAdTime={currentAdTime}
        />
      )}
    </div>
  );
};
