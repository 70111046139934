import { clsx } from 'clsx';
import type { PropsWithClassName } from '../../../types/react-props';
import styles from './Logo.module.scss';

export type LogoProps = PropsWithClassName<{
  readonly size?: 'medium' | 'small' | 'teaser' | 'header' | 'tiny';
  readonly variant?: 'default' | 'rtlPlus' | 'rtlDe';
  readonly colors?: [string, string, string];
}>;

const Logo = ({ size = 'medium', variant, colors, className }: LogoProps) => {
  const classNames = clsx(
    styles.logo,
    {
      [styles.small]: size === 'small',
      [styles.medium]: size === 'medium',
      [styles.teaser]: size === 'teaser',
      [styles.header]: size === 'header',
      [styles.tiny]: size === 'tiny',
    },
    className
  );

  if (variant === 'rtlPlus') {
    return (
      <svg className={classNames} viewBox="0 0 82 12" role="img" name="RTL+ Logo">
        <title>RTL+</title>
        <path fill={colors?.[0] ?? '#020203'} d="M0 0h21.338v12H0z" />
        <path
          fill="#fff"
          d="M6.061 2.758h5.686c1.731 0 2.695.778 2.695 2.084 0 1.12-.723 1.871-1.954 2.047l2.788 2.353h-2.139l-2.621-2.27H7.544v2.269H6.062V2.758h-.001Zm5.492 3c.917 0 1.361-.296 1.361-.898 0-.603-.444-.89-1.361-.89H7.544v1.787h4.009v.001Z"
        />

        <path fill={colors?.[1] ?? '#020203'} d="M23.47 0h21.338v12H23.47z" />
        <path fill="#fff" d="M33.397 4.036h-3.75V2.758h8.983v1.278h-3.75v5.206h-1.483V4.036Z" />

        <path fill={colors?.[2] ?? '#020203'} d="M46.939 0h21.338v12H46.939z" />
        <path fill="#fff" d="M53.57 2.758h1.481v5.206h6.594v1.278H53.57V2.758Z" />

        <path
          fill={colors ? '#fff' : '#020203'}
          d="M81.081 5.233h-3.496V2.594h-1.546v2.639h-3.493v1.548h3.493v2.621h1.546V6.781h3.496V5.233Z"
        />
      </svg>
    );
  }

  if (variant === 'rtlDe') {
    return (
      <svg className={classNames} viewBox="0 0 86 12" role="img" name="RTL.de Logo">
        <title>RTL.de</title>
        <path fill={colors?.[0] ?? '#020203'} d="M0 0h21.338v12H0z" />
        <path
          fill="#fff"
          d="M6.061 2.758h5.686c1.732 0 2.695.779 2.695 2.083 0 1.12-.723 1.872-1.954 2.048l2.788 2.353h-2.14l-2.62-2.269H7.542v2.269H6.06V2.758Zm5.492 3c.916 0 1.361-.296 1.361-.899 0-.601-.444-.889-1.361-.889h-4.01v1.787h4.01v.002Z"
        />

        <path fill={colors?.[1] ?? '#020203'} d="M23.47 0h21.339v12H23.47z" />
        <path fill="#fff" d="M33.397 4.036h-3.75V2.758h8.983v1.278h-3.75v5.206h-1.483V4.036Z" />

        <path fill={colors?.[2] ?? '#020203'} d="M46.938 0h21.339v12H46.938z" />
        <path fill="#fff" d="M53.57 2.758h1.481v5.205h6.594V9.24H53.57V2.758Z" />

        <path
          fill="#74767c"
          d="M73.78 9.227h-1.255V8.002h1.255v1.225Zm12.149 0h-4.387V2.775h4.387v1.013h-3.254v1.603h2.968v1.013h-2.968v1.807h3.254v1.016Zm-10.978-.002v-6.45h2.379c.62 0 1.163.134 1.631.4.467.268.83.644 1.087 1.129.258.486.387 1.051.387 1.697 0 .646-.131 1.194-.391 1.681a2.8 2.8 0 0 1-1.098 1.138c-.469.271-1.008.405-1.618.405h-2.377v.002l-.002-.002h.002Zm2.296-1.011c.652 0 1.153-.194 1.507-.581.354-.387.53-.931.53-1.632 0-.702-.176-1.253-.53-1.637-.354-.384-.855-.576-1.507-.576h-1.161v4.424h1.161v.002Z"
        />
      </svg>
    );
  }

  return (
    <svg className={classNames} viewBox="0 0 160 28" focusable="false" role="img" name="RTL Logo">
      <title>RTL</title>
      <path fill={colors?.[0] ?? '#0036f2'} d="M0 0h49.725v28H0z" />
      <path
        fill={colors?.[0] === '#ffffff' ? '#000' : '#fff'}
        d="M14.101 6.41h13.224c4.049 0 6.275 1.821 6.275 4.857 0 2.632-1.687 4.386-4.588 4.79l6.477 5.466h-4.993l-6.14-5.263H17.61v5.263h-3.441V6.41H14.1Zm12.82 7.017c2.159 0 3.17-.675 3.17-2.092s-1.011-2.092-3.17-2.092H17.61v4.184h9.31Z"
      />

      <path fill={colors?.[1] ?? '#00acf2'} d="M54.718 0h49.725v28H54.718z" />
      <path
        fill={colors?.[1] === '#ffffff' ? '#000' : '#fff'}
        d="M77.86 9.378h-8.77V6.41h20.982v2.968h-8.77v12.145H77.86V9.378Z"
      />

      <path fill={colors?.[2] ?? '#fa002e'} d="M109.436 0h49.725v28h-49.725z" />
      <path
        fill={colors?.[2] === '#ffffff' ? '#000' : '#fff'}
        d="M124.887 6.41h3.44v12.144h15.384v2.969h-18.824V6.41Z"
      />
    </svg>
  );
};

export default Logo;
