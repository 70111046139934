import { type RefObject, useEffect, useRef } from 'react';

export const useScrollToCurrentSelection = (selectedIndex: number): RefObject<HTMLDivElement> => {
  const scrollRef = useRef<HTMLDivElement>(null as HTMLDivElement | null);

  useEffect(() => {
    if (!scrollRef.current?.children) {
      return;
    }

    const selectedTeaser = scrollRef.current.children[selectedIndex] as HTMLDivElement;
    if (!selectedTeaser) {
      return;
    }

    scrollRef.current.scroll({
      left: selectedTeaser.offsetLeft - scrollRef.current.offsetLeft,
      behavior: 'smooth',
    });
  }, [selectedIndex]);

  return scrollRef;
};
