import { clsx } from 'clsx';
import type { PropsWithClassName } from '../../../types/react-props';
import styles from './SponsoredIcon.module.scss';

export type SponsoredIconProps = PropsWithClassName<{
  readonly withMargin?: boolean;
}>;

export const SponsoredIcon = ({ className }: SponsoredIconProps) => (
  <div className={clsx('sponsored-icon', styles.sponsored, className)}>Anzeige</div>
);

export const SponsoredIconFull = ({ className, withMargin = true }: SponsoredIconProps) => (
  <div className={clsx(styles.SponsoredIconFull, className, { [styles.withMargin]: withMargin })}>
    <div className={styles.SponsoredIconFullWrapper}>
      <div className={styles.SponsoredIconFullText}>
        <SponsoredIcon />
      </div>
    </div>
    <hr className="absolute width_full" />
  </div>
);
