'use client';
import { useContext } from 'react';
import { AppContext } from '../../../../context/AppContext';
import type { WidgetTeaserItemProps } from '../../../../types/cms-teaser';
import Pagination from '../../../UI/Pagination/Pagination';

export type WidgetPaginationProps = {
  readonly model: {
    readonly fields: {
      readonly items?: number;
    };
  };
  readonly items?: ReadonlyArray<WidgetTeaserItemProps>;
};

const WidgetPagination = ({ context }: { context: WidgetPaginationProps }) => {
  const { requestPath } = useContext(AppContext);
  const totalItems = context?.items?.length;
  const itemsPerPage = context?.model?.fields?.items ?? 12;
  const lastPage = totalItems ? Math.ceil(totalItems / itemsPerPage) : 1;

  if (!requestPath || lastPage <= 1) {
    return <></>;
  }

  const baseUrl = requestPath.split('.html')[0];
  const pageNumberRegex = baseUrl?.match(/seite-(\d+)\.html/);
  const currentPage = pageNumberRegex && pageNumberRegex[1] ? Number(pageNumberRegex[1]) : 1;

  return <Pagination baseUrl={baseUrl} currentPage={currentPage} lastPage={lastPage} />;
};

export default WidgetPagination;
