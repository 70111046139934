import { clsx } from 'clsx';
import { Icon } from '../Icon';
import Link from '../Link/Link';
import { Typography } from '../Typography';
import styles from './Pagination.module.scss';

export type PaginationProps = {
  baseUrl: string;
  currentPage: number;
  lastPage: number;
};

type PaginationItemProps = {
  href: string;
  number: number;
  active: boolean;
};

const PaginationItem = ({ href, number, active }: PaginationItemProps) => {
  if (active) {
    return (
      <Typography as="span" variant="m" className={styles.active}>
        {number}
      </Typography>
    );
  }
  return (
    <Link href={href}>
      <Typography as="span" variant="m">
        {number}
      </Typography>
    </Link>
  );
};

const SubPagination = ({ baseUrl, currentPage, lastPage }: PaginationProps) => {
  const firstSubPaginationPageNumber = currentPage * 10 + 1;
  const subPaginationNumbers = Array.from({ length: 10 }, (_, index) => firstSubPaginationPageNumber + index);

  return (
    <nav aria-label="Sub Pagination">
      <ul className={styles.subPagination}>
        {subPaginationNumbers.map(
          (number) =>
            number <= lastPage && (
              <li key={`${baseUrl}-${number}`} className={styles.subItem}>
                <Link href={`${baseUrl}/seite-${number}.html`}>
                  <Typography as="span" variant="4xs">
                    {number}
                  </Typography>
                </Link>
              </li>
            )
        )}
      </ul>
    </nav>
  );
};

const Pagination = ({ baseUrl, currentPage, lastPage }: PaginationProps) => {
  const startingNumber = lastPage - currentPage <= 5 ? lastPage - 9 : currentPage - 4;
  const firstStartingNumber = currentPage <= 5 || lastPage <= 10 ? 1 : startingNumber;
  const paginationNumbers = Array.from({ length: 10 }, (_, index) => firstStartingNumber + index);

  return (
    <div>
      <nav aria-label="Pagination" className={styles.root}>
        {currentPage !== 1 && (
          <div className={clsx(styles.gridItem, styles.prevWrapper)}>
            <Link href={baseUrl + '.html'} aria-label="Erstes Element" className={styles.firstItemLink}>
              <Icon name="chevronLeft" size={24} />
              <Icon name="chevronLeft" size={24} />
            </Link>
            <Link href={`${baseUrl}/seite-${currentPage - 1}.html`} aria-label="Zurück">
              <Icon name="chevronLeft" size={24} />
            </Link>
          </div>
        )}
        {currentPage !== lastPage && (
          <div className={clsx(styles.gridItem, styles.nextWrapper)}>
            <Link href={`${baseUrl}/seite-${currentPage + 1}.html`} aria-label="Weiter">
              <Icon name="chevronRight" size={24} />
            </Link>
            <Link
              href={`${baseUrl}/seite-${lastPage}.html`}
              aria-label="Letztes Element"
              className={styles.lastItemLink}
            >
              <Icon name="chevronRight" size={24} />
              <Icon name="chevronRight" size={24} />
            </Link>
          </div>
        )}
        <ul className={clsx(styles.gridItem, styles.itemWrapper)}>
          {paginationNumbers.map((number) => {
            const href = `${baseUrl}${number === 1 ? '.html' : '/seite-' + number + '.html'}`;

            return (
              <li key={href} className={clsx(styles.item, number === currentPage && styles.active)}>
                <PaginationItem href={href} number={number} active={number === currentPage} />
              </li>
            );
          })}
        </ul>
      </nav>
      {currentPage <= 9 && currentPage * 10 < lastPage && (
        <SubPagination baseUrl={baseUrl} currentPage={currentPage} lastPage={lastPage} />
      )}
    </div>
  );
};

export default Pagination;
