import type { FeatureFlagNames, FeatureFlags } from 'api/getFeatureFlags';

export const isFeatureFlagEnabled = (pin: FeatureFlagNames, flags?: FeatureFlags): boolean => {
  const flagToggle = flags?.toggles.find((toggle) => toggle.name === pin);

  if (flagToggle) {
    return flagToggle.enabled;
  }

  return false;
};
