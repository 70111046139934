import { clsx } from 'clsx';
import { isMobile } from '../../../utils/detect';
import { responsiveMultiSpacing, responsiveStyle } from '../../../utils/styled';
import theme from '../../../utils/themes';
import { formatSecondsToMinutes } from '../../../utils/time';
import { Icon } from '../Icon';
import Image from '../Image/Image';
import { Typography } from '../Typography';
import type { VideoProps } from '../VideoPlayer/VideoPlayerDefault';
import styles from './VideoThumbnail.module.scss';

export interface VideoThumbnailProps {
  title: string;
  kicker: string;
  duration: number;
  isActive: boolean;
  video: VideoProps;
  onClick?: () => void;
  isAdPlaying?: boolean;
}

const { thumbnailWidth, thumbnailWidthMedium } = theme.videoSwiperDesign;

export const VideoThumbnail = ({
  title,
  kicker,
  duration,
  onClick,
  isActive = false,
  video,
  isAdPlaying,
}: VideoThumbnailProps) => {
  const width = isMobile() ? thumbnailWidth : thumbnailWidthMedium;

  return (
    <button onClick={onClick} className={styles.main} aria-label={`Video abspielen: ${title}`}>
      <Typography
        as="div"
        className={styles.poster}
        style={{
          ...responsiveStyle('width', `${theme.videoSwiperDesign.thumbnailWidth}px`),
        }}
        marginBottom="04"
      >
        <Image image={video.image} alt={`${title} ${kicker}`} width={width} />
        {isActive && !isAdPlaying && <div className={styles.activeIndicator}>läuft gerade</div>}
        <div
          className={clsx(styles.duration, { [styles.durationActive]: isActive })}
          style={responsiveMultiSpacing('padding', ['02', '04'])}
        >
          <Icon name="playOutline" />
          {formatSecondsToMinutes(duration, 'min')}
        </div>
      </Typography>
      <Typography variant="4xs" marginBottom="02">
        {kicker}
      </Typography>
      <Typography variant="m" marginBottom="04">
        {title}
      </Typography>
    </button>
  );
};
