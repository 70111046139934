import { UUID } from '../../../utils/idGenerator';
import { Logo } from '../Logo';
import styles from './NewsTicker.module.scss';

type Item = {
  readonly title: string;
  readonly category: string;
  readonly timestamp: string;
  readonly linkHref: string;
  readonly logoColors?: [string, string, string];
};

export type NewsTickerProps = {
  readonly items: ReadonlyArray<Item>;
};

const NewsTicker = ({ items }: NewsTickerProps) => {
  return (
    <div className={styles.items}>
      {items.map((item) => (
        <div key={UUID()} className={styles.item}>
          <Logo size="tiny" colors={item.logoColors ?? ['#000', '#000', '#000']} />
          <a href={item.linkHref} className={styles.link}>
            <h3 className={styles.title}>{item.title}</h3>
          </a>
          <div className={styles.subtitle}>
            <span>{item.timestamp}</span>
            <span className={styles.category}>{item.category}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default NewsTicker;
