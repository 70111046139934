import { useContext } from 'react';
import { AppContext } from '../context/AppContext';
import { useQueryParam } from './useQueryParam';

export const useAdDebug = (): boolean => {
  const adDebug = useQueryParam('ad.debug');
  const { adDebugMode } = useContext(AppContext);

  if (adDebug !== null) {
    return true;
  }

  if (adDebugMode) {
    return true;
  }

  return false;
};
