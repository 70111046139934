import type { ReactNode } from 'react';
import { clsx } from 'clsx';
import type { PropsWithClassName } from '../../../types/react-props';
import { Icon } from '../Icon';
import Link from '../Link/Link';
import styles from './Title.module.scss';

export type TitleLink = {
  readonly noFollow?: boolean;
  readonly url?: string;
  readonly name?: string;
  readonly target: string;
  readonly type: 'section-link' | 'external-link';
};

export type TitleProps = PropsWithClassName<{
  readonly children: ReactNode;
  readonly link?: TitleLink;
  readonly as?: 'h2' | 'h3' | 'div';
  readonly plainLink?: boolean;
}>;

const Title = ({ link, children, as: DynamicTag = 'h2', className, plainLink }: TitleProps) => {
  if (!link?.url) {
    return (
      <div className={clsx(styles.root, className)}>
        <DynamicTag className={styles.title} data-id="section-title">
          {children}
        </DynamicTag>
      </div>
    );
  }

  return (
    <Link
      href={link.url}
      target={link.target}
      className={clsx(styles.root, styles.link, className)}
      noFollow={link.noFollow}
      plainLink={plainLink}
    >
      <DynamicTag className={styles.title} data-id="section-title">
        {children}
      </DynamicTag>
      <Icon name="chevronRightNew" size={24} />
    </Link>
  );
};

export default Title;
