'use client';
import { useContext } from 'react';
import { AppContext } from 'context/AppContext';
import { isFeatureFlagEnabled } from 'utils/featureFlags';
import type { WidgetTeaserItemProps } from '../../../../../types/cms-teaser';
import { AdInsideList } from '../../../../UI/AdInsideList/AdInsideList';
import { TeaserGridSection } from '../../../../UI/TeaserGridSection';
import { TeaserGridSection as TeaserGridSectionNew } from '../../../../UI/TeaserGridSectionNew';
import type { TitleLink } from '../../../../UI/Title/Title';

export type WidgetTeaserAufmacherProps = {
  readonly model?: {
    readonly fields?: {
      readonly title?: string;
      readonly link?: TitleLink;
      readonly anchor?: string;
    };
  };
  readonly items?: ReadonlyArray<WidgetTeaserItemProps>;
};

const WidgetTeaserAufmacher = ({ context }: { context: WidgetTeaserAufmacherProps }) => {
  const { featureFlags } = useContext(AppContext);
  const isRefresh = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);
  const isTeaserRefresh = isFeatureFlagEnabled('ft_temp_enable_refresh_teasers', featureFlags);

  const width = [1200, 375];

  if (isRefresh || isTeaserRefresh) {
    const newWidth = [992, 735];

    return (
      <TeaserGridSectionNew
        title={context.model?.fields?.title}
        titleLink={context.model?.fields?.link}
        anchor={context.model?.fields?.anchor}
      >
        <AdInsideList context={context} teaserImageWidth={newWidth[1]} leadTeaserImageWidth={newWidth[0]} offset={-1} />
      </TeaserGridSectionNew>
    );
  }

  return (
    <TeaserGridSection
      title={context.model?.fields?.title}
      titleLink={context.model?.fields?.link}
      anchor={context.model?.fields?.anchor}
    >
      <AdInsideList context={context} teaserImageWidth={width[1]} leadTeaserImageWidth={width[0]} offset={-1} />
    </TeaserGridSection>
  );
};

export default WidgetTeaserAufmacher;
