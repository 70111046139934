import { clsx } from 'clsx';
import type { PropsWithClassName } from '../../../types/react-props';
import styles from './Iframe.module.scss';

type IframePorps = PropsWithClassName<{
  readonly src: string;
  readonly id: string;
  readonly width?: string;
  readonly height?: string;
}>;

const Iframe = ({ src, width = '100%', height = 'auto', id, className }: IframePorps) => (
  <iframe
    src={src}
    width={width}
    height={height}
    title={`widget-iframe-${id}`}
    allowFullScreen
    loading="lazy"
    className={clsx(styles.iframe, className)}
  />
);

export default Iframe;
