export type imageTypes = keyof typeof imageConfig;

export const imageConfig = {
  articleImage: {
    breakpoints: [768, 480],
    sizes: [1024, 768],
    fallback: 480,
  },
  opener: {
    breakpoints: [1200, 768, 360],
    sizes: [1200, 1024, 768],
    fallback: 360,
  },
  defaultTeaser: {
    breakpoints: [1200, 768],
    sizes: [360, 240],
    fallback: 124,
  },
  doubleTeaser: {
    breakpoints: [480, 360],
    sizes: [768, 480],
    fallback: 360,
  },
  fiveFoldTeaser: {
    breakpoints: [1200, 768],
    sizes: [360, 240],
    fallback: 124,
  },
};
