import { clsx } from 'clsx';
import Icon from '../Icon/Icon';
import styles from './NavButtonsNew.module.scss';

interface NavButtonsProps {
  scrollIndex: number;
  scrollLeft: (howFar: number) => void;
  scrollRight: (howFar: number) => void;
  videosCount: number;
  className: string;
}

const NavButtonsNew = ({ scrollIndex, scrollLeft, scrollRight, videosCount, className }: NavButtonsProps) => (
  <div className={className}>
    {scrollIndex > 0 && (
      <button
        className={`${clsx(styles.button, styles.buttonLeft)} navButtons`}
        onClick={() => scrollLeft(2)}
        data-testid="button-left"
        aria-label="Vorherige Videos"
      >
        <Icon name="chevronLeft" size={25} />
      </button>
    )}
    {scrollIndex < videosCount - 3 && (
      <button
        className={`${clsx(styles.button, styles.buttonRight)} navButtons`}
        onClick={() => scrollRight(2)}
        data-testid="button-right"
        aria-label="Nächste Videos"
      >
        <Icon name="chevronRight" size={25} />
      </button>
    )}
  </div>
);

export default NavButtonsNew;
