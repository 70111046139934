export enum AdPlatform {
  Desktop = 'desktop',
  Mobile = 'mobile',
}

export enum AdSlotName {
  Skyscraper = 'skyscraper',
  Mobile = 'mobile',
  Dmofooter = 'dmofooter',
  Superbanner = 'superbanner',
  Rectangle = 'rectangle',
  Teaser = 'teaser',
  Wallpaper = 'wallpaper',
  Outstream = 'outstream_div',
  Opinary = 'opinary-automation-placeholder',
  Outbrain = 'outbrain',
  OutbrainMobile = 'outbrain-mobile',
}

export enum AdSlotType {
  Desktop = 'desktop',
  Mobile = 'mobile',
  Default = 'default',
}

export interface AdSizePredictionItem {
  height: number;
  percentile90: number;
  fp: boolean;
}

export interface AdSizePredictionZone {
  [type: string]: AdSizePredictionItem;
}

export interface AdSizePredictionBase {
  [zone: string]: AdSizePredictionZone;
}

export interface AdSizePredictionProps {
  mobile: AdSizePredictionBase;
  desktop: AdSizePredictionBase;
}

export interface AdsProps {
  name: AdSlotName;
  position?: number;
  viewport?: AdSlotType;
  outbrainWidgetId?: string;
  skipPrediction?: boolean;
}

export interface AdsPropsInList extends AdsProps {
  positionInList: number;
}

export interface AdsPropsBeforeArea extends AdsProps {
  beforeArea: number;
}
