import { clsx } from 'clsx';
import { type LinkProps } from 'next/link';
import Image, { type ImageProps } from 'components/UI/Image/Image';
import Link from 'components/UI/Link/Link';
import type { PropsWithClassName } from 'types/react-props';
import { formatMillisecondsToMinutes } from 'utils/time';
import { linkUrl } from 'utils/url';
import { Icon } from '../Icon';
import { Logo } from '../Logo';
import { SponsoredIcon } from '../SponsoredIcon';
import styles from './TeaserNew.module.scss';

export type TeaserNewProps = PropsWithClassName<
  {
    readonly title: string;
    readonly linkHref: LinkProps['href'];
    readonly image?: ImageProps;
    readonly subtitle?: string;
    readonly variant?: 'small' | 'medium' | 'large';
    readonly logoColors?: [string, string, string];
    readonly ctaColor?: string;
    readonly isSponsored?: boolean;
  } & (
    | { isGallery: true; galleryCount: number; isVideo?: false; isRtlPlus?: false; videoDuration?: never }
    | { isVideo: true; videoDuration: number; isGallery?: false; isRtlPlus?: false; galleryCount?: never }
    | { isRtlPlus: true; isGallery?: false; isVideo?: false; galleryCount?: never; videoDuration?: never }
    | { isGallery?: false; isVideo?: false; isRtlPlus?: false; galleryCount?: never; videoDuration?: never }
  )
>;

const TeaserNew = ({
  title,
  subtitle,
  linkHref,
  image,
  variant = 'medium',
  logoColors,
  ctaColor,
  isSponsored = false,
  isGallery = false,
  isVideo = false,
  isRtlPlus = false,
  galleryCount,
  videoDuration,
  className,
}: TeaserNewProps) => (
  <div
    className={clsx(styles.teaser, className, {
      [styles.small]: variant === 'small',
      [styles.medium]: variant === 'medium',
      [styles.large]: variant === 'large',
    })}
    data-id={variant === 'large' ? 'lead' : undefined}
  >
    <div className={styles.imageWrapper}>
      {/* TODO: add sizes */}
      <Image image={image?.image} className={styles.image} alt={image?.alt} width={image?.width} src={image?.src} />
      {isSponsored && <SponsoredIcon className={styles.sponsored} />}
      {isGallery && variant === 'small' && (
        <div className={clsx(styles.button)}>
          <Icon className={styles.iconGallery} name="gallery" size={16} />
          {galleryCount}
        </div>
      )}
      {isVideo && variant === 'small' && (
        <div className={clsx(styles.button)}>
          <Icon className={styles.iconPlay} name="play" size={16} />
          {!!videoDuration && formatMillisecondsToMinutes(videoDuration, '', false)}
        </div>
      )}
    </div>
    <div className={clsx(styles.contentWrapper, { [styles.paddingBottom]: !isVideo && !isRtlPlus })}>
      <Link
        className={styles.link}
        href={linkUrl(linkHref)}
        target={isRtlPlus ? '_blank' : undefined}
        data-tracking-id="teaser-link"
      >
        <h3 className={styles.title}>{title}</h3>
      </Link>
      {!!subtitle && <div className={styles.subtitle}>{subtitle}</div>}
      {(isVideo || isRtlPlus) && variant !== 'small' && (
        <div
          className={clsx(styles.button)}
          style={{ backgroundColor: !!ctaColor && !isRtlPlus ? ctaColor : undefined }}
        >
          <Icon className={styles.iconPlay} name="play" size={20} />
          {isVideo && (
            <span>
              Video {!!videoDuration && `[ ${formatMillisecondsToMinutes(videoDuration, '', false)} ]`} • Jetzt ansehen
            </span>
          )}
          {isRtlPlus && (
            <>
              <span>
                Jetzt <strong>LIVE bei RTL+</strong> streamen
              </span>
              <Icon className={styles.iconExternalLink} name="externalLink" size={16} />
            </>
          )}
        </div>
      )}
    </div>
    {isGallery && variant !== 'small' && (
      <div className={clsx(styles.galleryCount)}>
        <Icon name="gallery" size={16} />
        {galleryCount}
      </div>
    )}
    {variant !== 'small' && (
      <Logo
        className={styles.rtlLogo}
        size="teaser"
        variant={isRtlPlus ? 'rtlPlus' : 'default'}
        colors={isRtlPlus ? ['#ff5c01', '#d4000d', '#6e0ced'] : logoColors}
      />
    )}
  </div>
);

export default TeaserNew;
