'use client';
import { useContext, useEffect, useRef, useState } from 'react';
import { type FoundationError, type PlayerInterface } from '@foundation-player/loader';
import { createPlayerUI } from '@foundation-player/ui';
import { clsx } from 'clsx';
import { AppContext } from 'context/AppContext';
import { useConsent } from 'customHooks/useConsent';
import { useQueryExist } from 'customHooks/useQueryExist';
import { getImageLoader } from 'utils/imageLoader';
import type { VideoProps } from '../VideoPlayer/VideoPlayerDefault';
import { createPlayerLoaderConfig, createMediaConfig, createUiConfig } from './config';
import styles from './FoundationPlayer.module.scss';

export type FoundationPlayerProps = {
  video: VideoProps;
  postRollDisabled?: boolean;
  autoplay?: boolean;
  isAdPlayingListener?: (value: boolean) => void;
  totalClipsListener?: (value: number) => void;
  totalDurationListener?: (value: number) => void;
  currentAdTimeListener?: (value: number) => void;
  contentFinishedListener?: (value: boolean) => void;
  videoPlayingListener?: (value: boolean) => void;
};

const FoundationPlayer = ({
  video,
  postRollDisabled,
  autoplay = false,
  isAdPlayingListener,
  totalClipsListener,
  totalDurationListener,
  currentAdTimeListener,
  contentFinishedListener,
  videoPlayingListener,
}: FoundationPlayerProps) => {
  const {
    analyticsData: { ivwCode },
    pageMetaData: { noVideoAds },
  } = useContext(AppContext);
  const {
    consent: { tcString },
    isConsentReady,
  } = useConsent();
  const adsDisabled = useQueryExist('video.adsOff') || noVideoAds || video.fields['ads-free'] === 'true';
  const playerRef = useRef<PlayerInterface>();
  const playerElementRef = useRef<HTMLDivElement>(null);

  const posterUrl = getImageLoader(video.image.baseUrl, video.image.filename, 'c16_9').loader({
    src: video.image.baseUrl,
    width: 1024,
  });

  const mediaConfig = createMediaConfig({
    ads: !adsDisabled,
    ivwCode,
    postRoll: !postRollDisabled,
    poster: posterUrl,
    tcString,
    video,
    autoplay,
  });

  const [playerCreated, setPlayerCreated] = useState<boolean>(false);

  async function createPlayerUIAndLoadMedia() {
    try {
      if (playerElementRef.current) {
        setPlayerCreated(true);
        const uiConfig = createUiConfig(posterUrl);
        const playerLoaderConfig = createPlayerLoaderConfig(tcString, !adsDisabled);
        playerRef.current = await createPlayerUI(playerElementRef.current, playerLoaderConfig, uiConfig);
        playerRef.current.loadMedia(mediaConfig).catch((error: FoundationError) => {
          playerRef.current?.destroy(error);
        });

        playerRef.current?.addEventListener('advertising:AdSlotStarted', (e) => {
          isAdPlayingListener && isAdPlayingListener(true);
          totalClipsListener && totalClipsListener(e.adSlot.getClips());
          totalDurationListener && totalDurationListener(e.adSlot.getDuration());
        });
        playerRef.current?.addEventListener(
          'advertising:AdSlotStopped',
          () => isAdPlayingListener && isAdPlayingListener(false)
        );
        playerRef.current?.addEventListener(
          'player:TimeChanged',
          (e) => currentAdTimeListener && currentAdTimeListener(e.time)
        );
        playerRef.current?.addEventListener('player:ContentStart', () => {
          videoPlayingListener && videoPlayingListener(true);
        });
        playerRef.current?.addEventListener('player:ContentEnd', () => {
          contentFinishedListener && contentFinishedListener(true);
          videoPlayingListener && videoPlayingListener(false);
        });
      }
    } catch (e) {
      console.log('Error:', e);
    }
  }

  useEffect(() => {
    if (isConsentReady) {
      createPlayerUIAndLoadMedia();
    }

    return () => {
      playerRef.current?.removeEventListener('advertising:AdSlotStarted', () => null);
      playerRef.current?.removeEventListener('advertising:AdStopped', () => null);
      playerRef.current?.removeEventListener('player:TimeChanged', () => null);
      playerRef.current?.removeEventListener('player:ContentEnd', () => null);
      playerRef.current?.removeEventListener('player:ContentStart', () => null);
      playerRef.current?.destroy('unmount');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConsentReady]);

  useEffect(() => {
    playerRef.current?.loadMedia(mediaConfig).catch((error: FoundationError) => {
      playerRef.current?.destroy(error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video.id]);

  return (
    <div ref={playerElementRef}>
      <video poster={posterUrl} data-testid={video.id} className={clsx(styles.video, playerCreated && styles.hide)}>
        <track kind="captions" />
      </video>
    </div>
  );
};

export default FoundationPlayer;
