import { formatSecondsToMinutes } from '../../../utils/time';
import { Typography } from '../Typography';
import { getVideoDuration } from '../VideoPlayer/helper/utils';
import type { VideoProps } from '../VideoPlayer/VideoPlayerDefault';
import styles from './Creditbox.module.scss';

interface Props {
  video: VideoProps;
}

export const CreditBox = ({ video }: Props) => (
  <div className={styles.box}>
    <Typography variant="4xs" className={styles.time} as="div" textAlign="left" marginBottom="02">
      {formatSecondsToMinutes(getVideoDuration(video.fields.encoding), 'min')}
    </Typography>
    <Typography as="div" variant="4xs" marginBottom="02">
      {video.fields.subtitle}
    </Typography>
    <Typography variant="m" as="div">
      {video.fields.title}
    </Typography>
  </div>
);
