export const getRelProperty = (noFollow?: boolean, target?: string, sponsored?: boolean, referrer?: boolean) => {
  const relProperties = [];
  if (target === '_blank') {
    relProperties.push('noopener');

    if (!referrer) {
      relProperties.push('noreferrer');
    }
  }

  if (noFollow) {
    relProperties.push('noFollow');
  }

  if (sponsored) {
    relProperties.push('sponsored');
  }

  return relProperties.length === 0 ? undefined : relProperties.join(' ');
};

export const getReferrer = (uri?: string) => {
  return uri?.includes('plus.rtl.de') || uri?.includes('tvnow.de');
};
