'use client';
import { createContext, useContext, useMemo } from 'react';
import dynamic from 'next/dynamic';
import type { VideoPlayerDefaultProps } from './VideoPlayerDefault';
import VideoPlayerPoster from './VideoPlayerPoster';

const LoadingValueContext = createContext({} as VideoPlayerDefaultProps);

const PlayerDefault = dynamic<VideoPlayerDefaultProps>(() => import('./VideoPlayerDefault'), {
  ssr: false,
  loading: () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { video } = useContext(LoadingValueContext);
    return <VideoPlayerPoster image={video.image} altText={video.fields.title} />;
  },
});

const VideoPlayer = ({ video, startType = 0, onEvents, noPreroll, noPostroll, autoplay }: VideoPlayerDefaultProps) => {
  const params = useMemo(
    () => ({ video, onEvents, startType, noPreroll, noPostroll }),
    [noPostroll, noPreroll, onEvents, startType, video]
  );

  return (
    <LoadingValueContext.Provider value={params}>
      <PlayerDefault
        video={video}
        startType={startType}
        onEvents={onEvents}
        noPreroll={noPreroll}
        noPostroll={noPostroll}
        autoplay={autoplay}
      />
    </LoadingValueContext.Provider>
  );
};

export default VideoPlayer;
