'use client';

import type { ReactNode } from 'react';
import { clsx } from 'clsx';
import type { PropsWithClassName } from '../../../types/react-props';
import { Icon } from '../Icon';
import Link from '../Link/Link';
import { Logo } from '../Logo';
import { Typography } from '../Typography';
import styles from './Title.module.scss';

export type TitleLink = {
  readonly noFollow?: boolean;
  readonly url?: string;
  readonly name?: string;
  readonly target: string;
  readonly type: 'section-link' | 'external-link';
};

export type TitleProps = PropsWithClassName<{
  readonly children: ReactNode;
  readonly link?: TitleLink;
  readonly as?: 'h2' | 'h3' | 'div';
  readonly isRtlPlus?: boolean;
  readonly plainLink?: boolean;
}>;

const Title = ({ link, children, as = 'h2', isRtlPlus = false, className, plainLink }: TitleProps) => {
  if (!link?.url) {
    return (
      <div className={clsx(styles.title, className)}>
        <div>
          <Typography as={as} variant="m">
            {children}
          </Typography>
        </div>
        {isRtlPlus && (
          <div>
            <Typography as="div" variant="4xs">
              Jetzt streamen auf
            </Typography>
            <Logo variant="rtlPlus" className={styles.rtlPlus} size="teaser" />
          </div>
        )}
      </div>
    );
  }

  return (
    <Link
      href={link.url}
      target={link.target}
      className={clsx(styles.title, styles.link, className)}
      noFollow={link.noFollow}
      plainLink={plainLink}
    >
      <div>
        <Typography as={as} variant="m">
          {children}
        </Typography>
        {!isRtlPlus && <Icon className={styles.icon} name="chevronRight" />}
      </div>
      {isRtlPlus && (
        <div>
          <Typography as="div" variant="4xs">
            Jetzt streamen auf
          </Typography>
          <Logo variant="rtlPlus" className={styles.rtlPlus} size="teaser" />
        </div>
      )}
    </Link>
  );
};

export default Title;
