'use client';
import { useEffect, useState } from 'react';
import { clsx } from 'clsx';
import type { GroupGridProps } from 'types/group';
import { generateWeakKey } from '../../../utils/idGenerator';
import Partials from '../../partials/Partials';
import type { MenuItemProps } from '../../partials/widget/widget-menu/widget-menu-default';
import { Icon } from '../Icon';
import Link from '../Link/Link';
import { Logo } from '../Logo';
import { MenuLink } from '../Menu';
import styles from './Header.module.scss';

export const Header = ({ context }: { context: GroupGridProps }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menu = context.children.find((child) => {
    return child.view === 'themenwelten';
  });

  useEffect(() => {
    const handler = (e: { code: string }) => {
      if (isOpen && e.code === 'Escape') {
        setIsOpen(false);
        document.body.classList.toggle('noScroll', false);
      }
    };

    window.addEventListener('keydown', handler, false);

    return () => {
      window.removeEventListener('keydown', handler, false);
    };
  });

  const toggleNaviLayer = () => {
    if (isOpen) {
      setIsOpen(false);
      document.body.classList.toggle('noScroll', false);
    } else {
      setIsOpen(true);
      document.body.classList.toggle('noScroll', true);
    }
  };

  const getActiveMenuItem = () => {
    return menu?.model.menu.find((item: MenuItemProps) => {
      return item.active;
    });
  };

  return (
    <>
      <Link href="/" className={styles.logo}>
        <Logo variant="rtlDe" size="header" colors={getActiveMenuItem()?.themenwelt?.logo_colors ?? undefined} />
      </Link>
      <header className={styles.mainNavigation}>
        <ul className={styles.menu}>
          {menu?.model.menu.map((item: MenuItemProps) => (
            <li
              className={clsx(styles.menuItem, item.active ? styles.active : '')}
              key={item.title}
              style={{ borderBottom: `${item?.themenwelt?.primary_color ?? '#000000'} 2px solid` }}
            >
              <MenuLink menuItem={item} dataVarsAction="level_1" />
            </li>
          ))}
        </ul>
      </header>
      <button className={clsx(styles.search, styles.button)} onClick={toggleNaviLayer} aria-label="Navigation & Suche">
        <Icon name="search" size={18} viewBox="0 0 18 18" />
      </button>
      <div className={isOpen ? styles.open : styles.closed} data-testid={isOpen ? 'open' : 'closed'}>
        <div className={styles.content}>
          <button className={clsx(styles.button, styles.close)} onClick={toggleNaviLayer} aria-label="Schließen">
            <Icon name="close" size={16} />
          </button>
          {context.children.map((context) => (
            <Partials key={generateWeakKey(context, 'navi-layer')} context={context} />
          ))}
        </div>
        <div className={styles.backdrop} onKeyDown={() => {}} onClick={toggleNaviLayer} role="button" tabIndex={-1} />
      </div>
    </>
  );
};
