'use client';

import { type RefObject, useEffect, useState, useMemo } from 'react';
import { clsx } from 'clsx';
import { useScrollToCurrentSelection } from '../../../customHooks/useScrollToCurrentSelection';
import { isMobile } from '../../../utils/detect';
import theme from '../../../utils/themes';
import { formatSecondsToMinutes } from '../../../utils/time';
import { Title } from '../TitleNew';
import type { TitleLink } from '../TitleNew/Title';
import { getVideoDuration } from '../VideoPlayer/helper/utils';
import type { VideoProps } from '../VideoPlayer/VideoPlayerDefault';
import VideoThumbnailNew from '../VideoThumbnail/VideoThumbnailNew';
import NavButtonsNew from './NavButtonsNew';
import styles from './VideoSwiperNew.module.scss';

interface Props {
  videoList: Array<VideoProps>;
  currentVideoIndex?: number;
  onClick?: (currentVideoIndex: number) => void;
  title?: string;
  titleLink?: TitleLink;
  isAdPlaying?: boolean;
  totalAdClips?: number;
  totalAdDuration?: number;
  currentAdTime?: number;
  videoPlaying?: boolean;
}

const { thumbnailWidth, thumbnailWidthMedium, thumbnailGap, thumbnailGapMedium } = theme.videoSwiperDesign;

const VideoSwiperNew = ({
  videoList,
  currentVideoIndex,
  onClick,
  isAdPlaying = false,
  title,
  titleLink,
  totalAdClips = 0,
  totalAdDuration = 0,
  currentAdTime = 0,
  videoPlaying = false,
}: Props) => {
  const videosWithEncoding = useMemo(() => videoList.filter((video) => video.fields.encoding), [videoList]);
  const videosCount = videosWithEncoding.length;

  const [scrollIndex, setScrollIndex] = useState(0);

  const scrollRef = useScrollToCurrentSelection(scrollIndex);
  const adTime = totalAdDuration - currentAdTime;

  // force scroll to active video if video gets activated in parent component, e.g. in autoplay
  useEffect(() => {
    if (currentVideoIndex) {
      setScrollIndex(currentVideoIndex);
    }
  }, [currentVideoIndex]);

  if (videosCount === 0) {
    return <></>;
  }

  const switchCurrentVideo = (index: number) => {
    setScrollIndex(index);
    onClick?.(index);
  };

  const getCurrentScrollIndex = (ref: RefObject<HTMLDivElement>) =>
    Math.round(
      (ref?.current?.scrollLeft ?? 0) /
        (isMobile() ? thumbnailWidth + thumbnailGap : thumbnailWidthMedium + thumbnailGapMedium)
    );

  const scrollRight = (elementsCount: number) => {
    const newScrollIndex = Math.min(getCurrentScrollIndex(scrollRef) + elementsCount, videosCount - 1);
    setScrollIndex(newScrollIndex);
  };

  const scrollLeft = (elementsCount: number) => {
    const newScrollIndex = Math.max(getCurrentScrollIndex(scrollRef) - elementsCount, 0);
    setScrollIndex(newScrollIndex);
  };

  const navButtons = (
    <NavButtonsNew
      key={`right-${scrollIndex}`}
      scrollIndex={scrollIndex}
      scrollLeft={scrollLeft}
      scrollRight={scrollRight}
      videosCount={videosCount}
      className={styles.navButtons}
    />
  );

  return (
    <div className={styles.root} data-testid="video-swiper" aria-label="Playlist Swiper">
      {isAdPlaying && (
        <div className={clsx(styles.thumbnailOverlay)}>
          <span>{formatSecondsToMinutes(adTime)}</span>
          <span className={styles.adPlayingOverlayText}>
            {totalAdClips > 1 ? 'Gleich gehts weiter' : 'Nur ein Spot'}
          </span>
        </div>
      )}
      <div className={clsx(styles.swiperThumbnails, isAdPlaying ? styles.disableSwiper : null)}>
        <div className={styles.scrollOverlay} />
        {!!title && (
          <Title as="h2" className={styles.title} link={titleLink}>
            {title}
          </Title>
        )}
        <div className={clsx(styles.scrollContainer)} ref={scrollRef} aria-roledescription="Swiper" aria-live="polite">
          {videosWithEncoding.map((video: VideoProps, index: number) => (
            <VideoThumbnailNew
              isActive={currentVideoIndex === index && videoPlaying}
              key={video.id}
              title={video.fields.title}
              kicker={video.fields.subtitle ?? ''}
              duration={getVideoDuration(video.fields.encoding)}
              video={video}
              isAdPlaying={isAdPlaying}
              onClick={() => {
                switchCurrentVideo(index);
              }}
              aria-current={currentVideoIndex === index}
            />
          ))}
          {navButtons}
        </div>
      </div>
    </div>
  );
};

export default VideoSwiperNew;
