import type { MutableRefObject } from 'react';
import type { PlayerEvent } from '../../../../../types/video';
import { videoEvents } from '../../../../../utils/video';

export interface CbcPlayerOnEvent {
  setShowRelatedVideos: (showSwiper: boolean) => void;
  startType: MutableRefObject<number>;
  setIsAdPlaying: (isAdPlaying: boolean) => void;
  setTotalClips: (totalClips: number) => void;
  setTotalDuration: (totalDuration: number) => void;
  setCurrentAdTime: (currentAdTime: number) => void;
}

export const videoplayerOnEvents =
  ({ setShowRelatedVideos, setIsAdPlaying, setTotalDuration, setCurrentAdTime, setTotalClips }: CbcPlayerOnEvent) =>
  (event: PlayerEvent) => {
    if (event.type === 'onSessionEnd') {
      setShowRelatedVideos(true);
    }

    videoEvents({
      setIsAdPlaying,
      setTotalClips,
      setTotalDuration,
      setCurrentAdTime,
    })(event);
  };
