'use client';
import type { ReactNode } from 'react';
import { useContext } from 'react';
import { clsx } from 'clsx';
import { AppContext } from '../../../context/AppContext';
import { AdSlotType, type AdSizePredictionProps } from '../../../types/ads.d';
import styles from '../../UI/AdSlot/AdSlot.module.scss';
import { isMobileAd } from '../../UI/AdSlot/AdSlotHelper';

interface PredictionMinHeightProps {
  adSizePrediction: AdSizePredictionProps | undefined;
  adSlotName: string;
  adZone: string;
  isMobile: boolean;
}

interface AdSlotPredictionProps {
  adSlotName: string;
  children: ReactNode;
}

export const predictionMinHeight = ({ adSizePrediction, adSlotName, adZone, isMobile }: PredictionMinHeightProps) => {
  if (!adSizePrediction) {
    return '';
  }

  const predictionEnv = adSizePrediction[isMobile ? AdSlotType.Mobile : AdSlotType.Desktop];
  const predictionZone = predictionEnv[adZone];
  return predictionZone?.[adSlotName] ? `${predictionZone[adSlotName].percentile90}px` : 'auto';
};

export const AdSizePrediction = ({ adSlotName, children }: AdSlotPredictionProps) => {
  const {
    pageMetaData: { adZone, noAds },
    adSizePrediction,
  } = useContext(AppContext);

  const isMobile = isMobileAd(adSlotName);
  const minHeight = predictionMinHeight({
    adSizePrediction,
    adSlotName,
    adZone: adZone,
    isMobile: isMobile,
  });

  if (noAds) {
    return null;
  }

  return (
    <div
      className={`adSlotName ${isMobile ? clsx(styles.mobile) : clsx(styles.desktop)}`}
      style={{ minHeight: `${minHeight}` }}
    >
      {children}
    </div>
  );
};
