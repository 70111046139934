'use client';
import type { FormEventHandler } from 'react';
import { Icon } from '../Icon';
import Link from '../Link/Link';
import { Typography } from '../Typography';
import styles from './Search.module.scss';

export const MOST_SEARCHED_ITEMS = [
  {
    label: 'Sendungen A-Z',
    link: '/sendungen/a-z.html',
  },
  {
    label: 'GZSZ',
    link: '/cms/sendungen/gzsz.html',
  },
  {
    label: 'Corona',
    link: '/cms/coronavirus.html',
  },
  {
    label: 'TV Programm',
    link: '/fernsehprogramm',
  },
  {
    label: 'Abstimmen',
    link: '/specials/mitmachen',
  },
];

const Search = ({ handleSubmit }: { handleSubmit?: FormEventHandler<HTMLFormElement> }) => (
  <div className={styles.search}>
    <form className={styles.form} target="_top" action="/suche" onSubmit={handleSubmit}>
      <Icon className={styles.inputSearchIcon} name="search" />
      <input
        className={styles.input}
        type="search"
        name="q"
        placeholder="Suche nach Themen, Sendungen oder Stars"
        autoComplete="off"
      />
      <button className={styles.submit} type="submit">
        <Icon name="arrowRight" />
      </button>
    </form>

    <div>
      <Typography variant="4xs" as="div" className={styles.headline}>
        Meistgesucht
      </Typography>
      <ul className={styles.list}>
        {MOST_SEARCHED_ITEMS.map((item) => (
          <li key={item.label}>
            <Link className={styles.link} href={item.link}>
              <Icon name="search" size={16} />
              <Typography variant="4xs" as="span">
                {item.label}
              </Typography>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default Search;
