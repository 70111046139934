import { type ReactNode } from 'react';
import { clsx } from 'clsx';
import type { PropsWithClassName } from '../../../types/react-props';
import { Title, type TitleProps } from '../TitleNew';
import styles from './TeaserGridSection.module.scss';

export type TeaserGridSectionProps = PropsWithClassName<{
  readonly children: ReactNode;
  readonly title?: TitleProps['children'];
  readonly titleLink?: TitleProps['link'];
  readonly titleAs?: TitleProps['as'];
  readonly variant?: 'default' | 'small-aufmacher' | 'small-with-medium';
  readonly isRtlPlus?: boolean;
  readonly isSponsored?: boolean;
  readonly anchor?: string;
}>;

const TeaserGridSection = ({
  children,
  title,
  titleLink,
  titleAs = 'h2',
  variant = 'default',
  className,
  anchor,
}: TeaserGridSectionProps) => (
  <section className={clsx(styles.section, className)} id={anchor}>
    {!!title && (
      <Title link={titleLink} as={titleAs}>
        {title}
      </Title>
    )}
    <div
      className={clsx(styles.grid, {
        [styles.default]: variant === 'default',
        [styles.smallAufmacher]: variant === 'small-aufmacher',
        [styles.smallWithMedium]: variant === 'small-with-medium',
      })}
    >
      {children}
    </div>
  </section>
);

export default TeaserGridSection;
