'use client';
import { useEffect } from 'react';
import { usePathname } from 'next/navigation';
import { keepName } from './AdSlotHelper';

const OUTBRAIN_TEMPLATE = 'DE_RTL.de';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    OBR?: any;
  }
}

export const DefaultAd = ({
  adName,
  className,
  minHeight,
  fullAdSlotName,
  type,
}: {
  adName: string;
  className: string;
  minHeight: string;
  fullAdSlotName: string;
  type: string;
}) => (
  <div className={className} style={minHeight !== 'auto' ? { minHeight: `${minHeight}` } : {}}>
    <div
      id={fullAdSlotName}
      data-device={type}
      className={keepName(adName) ? '' : 'gujAd'}
      data-ada-container={`${fullAdSlotName}_${type}`}
    ></div>
  </div>
);

export const OutbrainAd = ({
  widgetId = 'SF_5',
  className,
  fullAdSlotName,
}: {
  widgetId?: string;
  className?: string;
  fullAdSlotName?: string;
}) => {
  const pathName = usePathname();
  const fullPath = `https://www.rtl.de${pathName}`;

  useEffect(() => {
    window?.OBR && window.OBR.extern.researchWidget();
  }, []);

  return (
    <div className={className} data-ada-container={fullAdSlotName} data-ob-identifier={`ad-outbrain-${widgetId}`}>
      <div className="OUTBRAIN" data-src={fullPath} data-widget-id={widgetId} data-ob-template={OUTBRAIN_TEMPLATE} />
    </div>
  );
};
