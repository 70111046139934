'use client';
import { useEffect, useRef, useState } from 'react';
import type { StorylineEmbedType } from '../../../../../types/storylines';
import styles from './Embed.module.scss';

export type OembedResponse = {
  html?: string;
  message?: string;
};

export default function Embed({ model }: { readonly model: StorylineEmbedType }) {
  const [fetchedData, setFetchedData] = useState<OembedResponse>();

  useEffect(() => {
    fetch(`https://embeds.rtl.de/oembed?url=${encodeURIComponent(model.fields.uri.value)}`)
      .then((res) => res.json())
      .then((data) => {
        setFetchedData(data);
      })
      .catch(() => setFetchedData({ message: 'error' }));
    return () => {
      setFetchedData(undefined);
    };
  }, [model]);

  if (fetchedData?.message) {
    return <></>;
  }

  return <>{fetchedData?.html ? <EmbedHTML script={fetchedData.html} /> : <div className={styles.loading}></div>}</>;
}

const EmbedHTML = ({ script }: { script: string }) => {
  const scriptRoot = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const range = document.createRange();
    const documentFragment = range.createContextualFragment(script);
    scriptRoot.current?.append(documentFragment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div ref={scriptRoot} data-testid="embed" className={styles.root} />;
};
