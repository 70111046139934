import { VideoPlayerControlsLoading } from '../VideoPlayerControls/VideoPlayerControlsLoading';
import styles from './Loader.module.scss';

export enum LoaderOptions {
  VideoWithRelated = 'videoWithRelated',
}
export const Loader = ({ type }: { type: LoaderOptions }) => {
  if (type == LoaderOptions.VideoWithRelated) {
    return (
      <div data-testid={`loader-${type}`}>
        <div className={styles.videoWithRelated}>
          <div className={styles.videoWithRelatedInside}>
            <VideoPlayerControlsLoading className={styles.loaderPadding} />
          </div>
        </div>
      </div>
    );
  }
  return null;
};
