'use client';

import { type RefObject, useEffect, useState, useMemo } from 'react';
import { clsx } from 'clsx';
import { useScrollToCurrentSelection } from '../../../customHooks/useScrollToCurrentSelection';
import { isMobile } from '../../../utils/detect';
import theme from '../../../utils/themes';
import { formatSecondsToMinutes } from '../../../utils/time';
import { Typography } from '../Typography';
import { getVideoDuration } from '../VideoPlayer/helper/utils';
import type { VideoProps } from '../VideoPlayer/VideoPlayerDefault';
import { VideoThumbnail } from '../VideoThumbnail';
import { NavButtons } from './NavButtons';
import styles from './VideoSwiper.module.scss';

interface Props {
  videoList: Array<VideoProps>;
  currentVideoIndex?: number;
  onClick?: (currentVideoIndex: number) => void;
  initHidden?: boolean;
  title?: string;
  isAdPlaying?: boolean;
  totalAdClips?: number;
  totalAdDuration?: number;
  currentAdTime?: number;
}

const { thumbnailWidth, thumbnailWidthMedium, thumbnailGap, thumbnailGapMedium, aspectRatio } = theme.videoSwiperDesign;

export const VideoSwiper = ({
  videoList,
  currentVideoIndex,
  onClick,
  initHidden = false,
  title,
  isAdPlaying = false,

  totalAdClips = 0,
  totalAdDuration = 0,
  currentAdTime = 0,
}: Props) => {
  const videosWithEncoding = useMemo(() => videoList.filter((video) => video.fields.encoding), [videoList]);
  const videosCount = videosWithEncoding.length;

  const [scrollIndex, setScrollIndex] = useState(0);

  const scrollRef = useScrollToCurrentSelection(scrollIndex);
  const adTime = totalAdDuration - currentAdTime;

  // force scroll to active video if video gets activated in parent component, e.g. in autoplay
  useEffect(() => {
    if (currentVideoIndex) {
      setScrollIndex(currentVideoIndex);
    }
  }, [currentVideoIndex]);

  if (videosCount === 0) {
    return <></>;
  }

  const switchCurrentVideo = (index: number) => {
    setScrollIndex(index);
    onClick?.(index);
  };

  const getCurrentScrollIndex = (ref: RefObject<HTMLDivElement>) =>
    Math.round(
      (ref?.current?.scrollLeft || 0) /
        (isMobile() ? thumbnailWidth + thumbnailGap : thumbnailWidthMedium + thumbnailGapMedium)
    );

  const scrollRight = (elementsCount: number) => {
    const newScrollIndex = Math.min(getCurrentScrollIndex(scrollRef) + elementsCount, videosCount - 1);
    setScrollIndex(newScrollIndex);
  };

  const scrollLeft = (elementsCount: number) => {
    const newScrollIndex = Math.max(getCurrentScrollIndex(scrollRef) - elementsCount, 0);
    setScrollIndex(newScrollIndex);
  };
  const height = Math.round(thumbnailWidthMedium / aspectRatio - 12);

  const navButtons = (
    <NavButtons
      key={`right-${scrollIndex}`}
      scrollIndex={scrollIndex}
      scrollLeft={scrollLeft}
      scrollRight={scrollRight}
      videosCount={videosCount}
      height={height}
      className={styles.navButtons}
    />
  );
  return (
    <div data-testid="video-swiper" className={initHidden ? styles.isHidden : styles.animationOnShow}>
      {isAdPlaying && (
        <div className={clsx(styles.thumbnailOverlay)}>
          <Typography variant="4xs">{formatSecondsToMinutes(adTime)}</Typography>
          <Typography variant="l" className={styles.adPlayingOverlayText}>
            {totalAdClips > 1 ? 'Gleich gehts weiter' : 'Nur ein Spot'}
          </Typography>
        </div>
      )}
      <div className={clsx(styles.swiperThumbnails, isAdPlaying ? styles.disableSwiper : null)}>
        <div className={styles.scrollOverlay} />
        {title && (
          <Typography variant="4xs" className={styles.otherVideos} marginBottom="04">
            {title}
          </Typography>
        )}
        <div className={clsx(styles.scrollContainer)} ref={scrollRef}>
          {videosWithEncoding.map((video: VideoProps, index: number) => (
            <VideoThumbnail
              isActive={currentVideoIndex === index}
              key={video.id}
              title={video.fields.title}
              kicker={video.fields.subtitle ?? ''}
              duration={getVideoDuration(video.fields.encoding)}
              video={video}
              isAdPlaying={isAdPlaying}
              onClick={() => {
                switchCurrentVideo(index);
              }}
            />
          ))}
          {navButtons}
        </div>
      </div>
    </div>
  );
};
