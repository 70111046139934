import { clsx } from 'clsx';
import Icon from '../Icon/Icon';
import styles from './NavButtons.module.scss';

interface NavButtonsProps {
  scrollIndex: number;
  scrollLeft: (howFar: number) => void;
  scrollRight: (howFar: number) => void;
  videosCount: number;
  height: number;
  className: string;
}

export const NavButtons = ({
  scrollIndex,
  scrollLeft,
  scrollRight,
  videosCount,
  height,
  className,
}: NavButtonsProps) => (
  <div className={className}>
    {scrollIndex > 0 && (
      <button
        className={`${clsx(styles.button, styles.buttonLeft)} navButtons`}
        style={{ height: height }}
        onClick={() => scrollLeft(2)}
      >
        <Icon name="chevronLeft" />
      </button>
    )}
    {scrollIndex < videosCount - 1 && (
      <button
        className={`${clsx(styles.button, styles.buttonRight)} navButtons`}
        style={{ height: height }}
        onClick={() => scrollRight(2)}
      >
        <Icon name="chevronRight" />
      </button>
    )}
  </div>
);
