import { clsx } from 'clsx';
import { useController, useLightboxState } from 'yet-another-react-lightbox';
import { Icon } from '../Icon';
import styles from './Gallery.module.scss';

export const GalleryButton = ({
  variant,
  trackGallery,
}: {
  variant: 'next' | 'prev';
  trackGallery: (currentIndex: number, length: number) => void;
}) => {
  const controller = useController();
  const { currentIndex, slides } = useLightboxState();

  const galleryEvent = (variant: 'next' | 'prev') => {
    const nextIndex = variant === 'next' ? getNextIndex(currentIndex, slides.length) : currentIndex;

    trackGallery(nextIndex, slides.length);

    controller[variant]({ count: undefined });
  };

  const getNextIndex = (currentIndex: number, length: number) => {
    return currentIndex + 1 === length ? currentIndex + 1 : currentIndex + 2;
  };

  return (
    <button
      className={clsx(`yarl__navigation_${variant}`, styles.button)}
      onClick={() => galleryEvent(variant)}
      disabled={variant === 'next' ? currentIndex === slides.length - 1 : currentIndex === 0}
    >
      {variant === 'next' ? (
        <Icon className={clsx(styles.galleryIcon, styles.right)} name="chevronRight" size={33} />
      ) : (
        <Icon className={clsx(styles.galleryIcon, styles.left)} name="chevronLeft" size={33} />
      )}
    </button>
  );
};
