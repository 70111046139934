'use client';
import { useState } from 'react';
import { Lightbox } from 'yet-another-react-lightbox';
import type { Slide } from 'yet-another-react-lightbox';
import { getGalleryRawData } from 'api/getGalleryRawData';
import type { ApiImageProps } from 'components/UI/Image';
import { Teaser } from 'components/UI/Teaser';
import type { BasisPageData, GalleryArticleModel } from 'types/cms';
import type { WidgetTeaserItemProps } from 'types/cms-teaser';
import { Icon } from '../Icon';
import { GalleryButton } from './GalleryButton';
import { GallerySlide } from './GallerySlide';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/counter.css';
import 'yet-another-react-lightbox/plugins/captions.css';

declare module 'yet-another-react-lightbox' {
  interface SlideImage {
    image: ApiImageProps;
    title: string;
    loading?: boolean;
    count: number;
    fields: {
      [key: string]: string | object | Array<object>;
    };
  }
}

export interface GalleryImage {
  model: {
    image: ApiImageProps;
    title: string;
    fields: {
      [key: string]: string | object | Array<object>;
    };
  };
}

interface Props {
  teaser: WidgetTeaserItemProps;
}

export const Gallery = ({ teaser }: Props) => {
  const [open, setOpen] = useState(false);
  const [galleryItems, setGalleryItems] = useState<Array<GalleryImage>>([]);

  const galleryUrl = new URL(teaser.model.url);

  const openGallery = () => {
    setOpen(true);
    if (galleryItems.length === 0) {
      getGalleryRawData(galleryUrl.pathname).then((res: BasisPageData) => {
        const galleryItems = res.areas.main
          ? res.areas.main.find((item: GalleryArticleModel) => item.view === 'gallery').items
          : [];

        trackGallery(1, galleryItems.length);
        setGalleryItems(galleryItems);
      });
    } else {
      trackGallery(1, galleryItems.length);
    }
  };

  const trackGallery = (currentIndex: number, length: number) => {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    window.dataLayer.push({
      media: undefined,
    });

    window.dataLayer.push({
      event: 'gallery_page_view',
      media: {
        type: 'gallery',
        url: teaser.model.url,
        id: teaser.model.id,
        first_headline: teaser.model.fields?.['seo-title'] ?? '',
        headline: teaser.model.fields.title,
        tags: teaser.model['tag_items']?.map((tag) => tag.name) ?? [],
        first_publication_datetime: teaser.model.publishedAt,
        last_update_datetime: teaser.model.lastmodifiedAt,
        editorial_unit: teaser.model.fields?.ressort ?? '',
        number_of_pictures: length,
        current_picture: currentIndex,
      },
    });
  };

  return (
    <>
      <Teaser
        headline={teaser.model.fields.title}
        kicker={teaser.model.fields.subtitle}
        onClick={openGallery}
        image={{
          image: teaser.model.image,
          width: 375,
          alt: teaser.model.fields.title,
        }}
        isGallery
        isSmallOnMobile
      />

      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={
          galleryItems.length > 0
            ? galleryItems.map((image, i) => ({
                src: image.model.image.defaultUrl,
                title: image.model.title,
                image: image.model.image,
                fields: image.model.fields,
                count: i + 1,
              }))
            : ([
                {
                  loading: true,
                },
              ] as Array<Slide>)
        }
        styles={{
          slide: { padding: 0 },
          button: { filter: 'none' },
          toolbar: { margin: '24px' },
        }}
        render={{
          slide: (props) => {
            const { slide } = props;
            return <GallerySlide slide={slide} />;
          },
          iconClose: () => {
            return <Icon name="cross" size={24} />;
          },
          buttonPrev: () => {
            return <GalleryButton variant="prev" trackGallery={trackGallery} />;
          },
          buttonNext: () => {
            return <GalleryButton variant="next" trackGallery={trackGallery} />;
          },
        }}
        carousel={{
          finite: true,
        }}
      />
    </>
  );
};
